import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../../redux/slice/categorySlice";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import SVGA from "svgaplayerweb";
import Button from "../../extras/Button";
import html2canvas from "html2canvas";
import { createSVGAGift } from "../../../redux/slice/giftSlice";
import { BASE_URL } from "../../utils/config";
import noImage from "../../../assets/images/noImage.jpg";

const SvgaDialogue = () => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.category);
  const [categoryData, setCategoryData] = useState("");
  const [svgaLinks, setSvgaLinks] = useState([
    "https://falla-res1.resygg.com/awss3_10000_1678786955114123834_3654846442.svga",
    "https://falla-res1.resygg.com/awss3_10000_1678786955924848623_1675228987.svga",
  ]);
  const [capturedImages, setCapturedImages] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setError] = useState({
    image: "",
    category: "",
  });
  const [image, setImage] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [isSvga, setIsSvga] = useState(false);
  const [imagePath, setImagePath] = useState(null);
  const [mongoId, setMongoId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState(null);

  console.log("imagePath", imagePath);
  console.log("capturedImages");
  console.log("imageData", imageData);
  console.log("imageUrl", imageUrl);
  console.log("image", image);

  useEffect(() => {
    dispatch(getAllCategory());
  }, [dispatch]);

  useEffect(() => {
    setIsSubmit(false);
  }, []);

  const handleInputImage = (e) => {
    setImage("");
    if (e.target.files[0]) {
      Object.assign(e.target.files[0], {
        preview: URL.createObjectURL(e.target.files[0]),
      });
      setImageData(e.target.files[0]);
      setImage([e.target.files[0]]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImagePath(reader.result);
        setError({
          image: "",
        });
      });
      reader.readAsDataURL(e.target.files[0]);

      if (e.target.files[0].name.split(".").pop() === "svga") {
        setIsSvga(true);
      } else {
        setIsSvga(false);
      }
    }
  };

  const onRemove = () => {
    setImagePath(null);
    setFile(null);
    setIsSvga(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (!categoryData) {
      const errors = {};
      if (!categoryData) {
        errors.category = "Category can't be blank!";
      }
      if (svgaLinks.length === 0) {
        errors.image = "Please select an Image!";
      }
      return setError({ ...errors });
    }

    formData.append("category", categoryData);
    formData.append("type", "link");
    formData.append("svgaImage", image);
    formData.append("image", imageData);

    const response = await dispatch(createSVGAGift(formData));

    if (response) {
      dispatch(getAllCategory());
    }

    console.log("response", response);

    dispatch(closeDialog());
  };

  const captureAndSendImage = (player, index) => {
    return new Promise((resolve) => {
      player.pauseAnimation();
      const canvasElement = document.querySelector(
        `div[attr="${index}"] canvas`
      );

      if (!canvasElement) {
        return;
      }

      html2canvas(canvasElement, {
        scale: 1,
        useCORS: true,
        backgroundColor: "rgba(0, 0, 0, 0)",
        onclone: (cloneDoc) => {
          const clonedCanvas = cloneDoc.querySelector(
            `div[attr="${index}"] canvas`
          );
          clonedCanvas.style.backgroundColor = "transparent";
        },
      }).then((canvas) => {
        canvas.toBlob((blob) => {
          const imageUrl = URL.createObjectURL(blob);
          resolve(blob);
          setImage(blob);
          // setImageUrl(imageUrl);
          // setCapturedImages((prevData) => {
          //   const newData = [...prevData];
          //   newData[index] = blob; // Ensure each index has only one thumbnail
          //   return newData;
          // });
          setIsSubmit(true);
        }, "image/png");
      });
    });
  };

  useEffect(() => {
    if (isSvga) {
      if (!!document.getElementById("svga") && imagePath) {
        var player = new SVGA.Player(`div[attr="${mongoId}"]`);
        var parser = new SVGA.Parser(`div[attr="${mongoId}"]`);
        if (imageData?.preview) {
          parser.load(imageData.preview, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
            setTimeout(() => {
              captureAndSendImage(player, mongoId);
            }, 3000);
          });
        }
        // else {
        //   parser.load(BASE_URL + dialogData1?.image, function (videoItem) {
        //     player.setVideoItem(videoItem);
        //     player.startAnimation();
        //     setTimeout(() => {
        //       captureAndSendImage(player, mongoId);
        //     }, 3000);
        //   });
        // }
      }
    } else {
      setIsSubmit(false);
    }
  }, [imageData, isSvga, imagePath]);

  return (
    <>
      <div
        className="dialog"
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-md-8 col-11">
              <div className="mainDiaogBox">
                <div className="row justify-content-between align-items-center formHead">
                  <div className="col-8">
                    <h2 className="text-theme m0">Gift Dialogue</h2>
                  </div>
                  <div className="col-4">
                    <div
                      className="closeButton"
                      onClick={() => {
                        dispatch(closeDialog());
                      }}
                    >
                      <i className="ri-close-line"></i>
                    </div>
                  </div>
                </div>
                <form id="expertForm">
                  <div
                    className="row align-items-start formBody"
                    style={{ overflowY: "hidden", overflowX: "hidden" }}
                  >
                    <div className="col-12">
                      <div className="inputData text flex-row justify-content-start text-start">
                        <label htmlFor="category" className="false undefined">
                          Select Category
                        </label>
                        <select
                          className="form-select form-control"
                          aria-label="Default select example"
                          value={categoryData}
                          onChange={(e) => {
                            setCategoryData(e.target.value);
                            if (e.target.value === "Select Category") {
                              return setError({
                                ...errors,
                                category: "Please select a Category!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                category: "",
                              });
                            }
                          }}
                        >
                          <option value="Select Category" selected>
                            Select Category
                          </option>
                          {category &&
                            category.map((category) => (
                              <option key={category._id} value={category._id}>
                                {category.name}
                              </option>
                            ))}
                        </select>
                        {errors.category && (
                          <div className="ml-2 mt-1">
                            {errors.category && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.category}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="form-group">
                      <label className="mb-2 mt-2 text-gray">Icon</label>

                      <>
                        <input
                          class="form-control"
                          type="file"
                          required=""
                          accept=".svga, .gift"
                          onChange={handleInputImage}
                        />
                        {errors.image && (
                          <div className="ml-2 mt-1">
                            {errors.image && (
                              <div className="pl-1 text__left">
                                <span className="text-red">{errors.image}</span>
                              </div>
                            )}
                          </div>
                        )}
                        {imagePath && (
                          <Fragment>
                            {!isSvga ? (
                              <>
                                <img
                                  src={imagePath}
                                  class="mt-3 rounded float-left mb-2"
                                  height="100px"
                                  width="100px"
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  id="svga"
                                  attr={mongoId}
                                  style={{
                                    boxShadow:
                                      "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                    float: "left",
                                    objectFit: "contain",
                                    marginRight: 15,
                                    width: "350px",
                                    marginBottom: "10px",
                                    height: "350px",
                                  }}
                                ></div>
                                <div className="closeButton closeIcon" onClick={onRemove}>
                                  <i className="ri-close-line"></i>
                                </div>
                              </>
                            )}
                          </Fragment>
                        )}
                      </>
                      {/* )} */}
                    </div>
                    {/* <div className="col-12">
                      <div className="form-group text-start">
                        <label className="mb-2 mt-2 text-gray">
                          SVGA Links
                        </label>
                        {svgaLinks.map((link, index) => (
                          <Fragment key={index}>
                            <div
                              id={`svga-${index}`}
                              attr={`${index}`}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                float: "left",
                                objectFit: "contain",
                                marginRight: 15,
                                width: "350px",
                                height: "350px",
                              }}
                            ></div>
                          </Fragment>
                        ))}
                      </div>
                    </div> */}
                  </div>
                  <div className="row formFooter">
                    <div className="col-12 text-end m0">
                      <Button
                        className="bg-gray text-light"
                        text="Cancel"
                        type="button"
                        onClick={() => dispatch(closeDialog())}
                      />
                      <Button
                        type="submit"
                        className="text-white m10-left"
                        style={{
                          backgroundColor: `${
                            isSubmit ? "#1ebc1e" : "#a1ffa1"
                          }`,
                        }}
                        text="Submit"
                        disabled={!isSubmit}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SvgaDialogue;
